import {useState, useEffect} from 'react';

// eslint-disable-next-line no-undef
export const mobileScreenValidation = () => window.innerWidth <= 1024;

export const useMobile = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [isPhone, setPhone] = useState(false);

  const handleResize = () => {
    setLoading(true);
    setPhone(false);
    // eslint-disable-next-line no-undef
    setIsMobile(mobileScreenValidation());
    // eslint-disable-next-line no-undef
    if (window.innerWidth < 768) {
      setPhone(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', handleResize);
  }, []);

  return [isMobile, loading, isPhone];
};
