import React from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import css from './styles.css';

const OcqJbqLoader = props => {
  const {isLoading} = props;
  return (
    <Styled id="OcqJbqLoader" css={css}>
      <div className={`loader-container ${!!isLoading && 'isLoading'}`}>
        <div className="loader-content">
          <img src="/file/general/ocq-loader.gif" alt="carregando" />
        </div>
      </div>
    </Styled>
  );
};

export default OcqJbqLoader;
