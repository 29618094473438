/* eslint-disable no-undef */

import {createStore} from '@oracle-cx-commerce/store';
import {createWapi} from '@oracle-cx-commerce/wapi';
import {loadVisitorService} from '@oracle-cx-commerce/react-app/utils';
import {preloadComponents} from '@oracle-cx-commerce/commerce-utils/react';
import {getPage, getGlobalContext, getSessionContext} from '@oracle-cx-commerce/commerce-utils/selector';
import {getCookieValue} from '@oracle-cx-commerce/utils/node';
import {render} from './render';

export const createClient = async ({
  endpoints,
  actions,
  components: originalComponents,
  middleware,
  subscribers = {}
}) => {
  // Get the initial state embedded into page at server-side render
  const {state} = window;

  // "clone" components object to make it mutable -- i.e. so properties can be redefined
  const components = {...originalComponents};

  console.assert(
    state,
    'Missing browser global window.state (i.e. the intial application state)--this should get set in the "index.html"'
  );

  const sessionContext = getSessionContext(state);

  // Auth token is not passed directly in state (for security and cache-ability reasons)
  if (sessionContext.token === true) {
    // If authenticated, extract token from cookie and put it in the state (before initialization)
    sessionContext.token = getCookieValue(document.cookie, 'access_token');
  }

  // Create the wapi client.
  const wapi = createWapi({endpoints});

  // Create the store.
  const store = createStore({actions, state, wapi, middleware});

  const {endpoint} = wapi;

  const {getState, subscribeDispatch} = store;

  // Register subscribers
  Object.values(subscribers).forEach(async subscriber => {
    // As subscribers should be passive--i.e. don't invoke store actions--the receive a restricted (read-only) store API. Subscribers can, however, invoke endpoints independent of the store.
    (await subscriber()).default({endpoint, getState, subscribeDispatch});
  });

  // Ensure the server rendered components are preloaded before hydration--this will prevent "hydration flicker", i.e. a momentary blank screen as the server content is deleted then re-rendered.
  await preloadComponents(components, state);

  if (getPage(state).isPreview || !getGlobalContext(state).production) {
    window.store = store;
    window.wapi = wapi;
  }

  let appIsHandlingError = false;

  addEventListener('error', async error => {
    if (!appIsHandlingError) {
      appIsHandlingError = true;

      await endpoint('logClientErrors', error, store.getState());

      appIsHandlingError = false;

      console.warn(`Error "${error.message}" has been logged with the OCC server`);
    }
  });

  /*
     Starts the client application.
    */
  const start = async () => {
    console.time('Hydrate');

    store.action('init');
    //store.action('initRecommendations', {url: location});

    render({components, store, container: document.querySelector('#root')});

    await store.action('getCloudConfiguration');

    loadVisitorService(store);

    console.timeEnd('Hydrate');
  };

  return {
    ...store,
    ...wapi,
    start
  };
};
