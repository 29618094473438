export const getOcqJbq = state => state.ocqJbq || {};

export const getData = state => getOcqJbq(state).data || {};

export const getOrganizationsResponse = state => getData(state).organizations || [];
export const getOrganizations = state => getOrganizationsResponse(state).items || [];
export const getOrganizationId = (state, id) => getOrganizations(state).find(item => item.id === id) || {};
export const getOrganizationCurrentProfile = state => getData(state).organizationCurrent || {};

const getLocalRepository = state => state.localRepository || {};

export const getIsTokenValidToShowCommission = state => getLocalRepository(state).isTokenValidToShowCommission || false;

export const getIsModalCommissionOpen = state => getLocalRepository(state).isModalCommissionOpen || false;

export const getRedirectToPageCommission = state => getLocalRepository(state).redirectToPageCommission || false;

export const getLoginSSO = state => getOcqJbq(state).loginSSO || {};
export const getDataSAML = state => getLoginSSO(state).dataSAML || {};
export const getAllBilling = state => getData(state).billings || [];
export const getAllBillingRequestDate = state => getAllBilling(state).billingRequestDate || {};
export const getAllBillingItems = state => getAllBilling(state).items || [];
export const getBilling = (state, orderId) =>
  getAllBillingItems(state).find(order => order.orderId === orderId)?.payments || [];
