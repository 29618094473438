/* eslint-disable no-undef */
/* eslint-disable unicorn/prefer-query-selector */
import React, {useState, createContext, useContext, useCallback, useEffect} from 'react';
import {getCurrentOrder} from '@oracle-cx-commerce/commerce-utils/selector';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {useNavigator} from '@oracle-cx-commerce/react-components/link';
import {useSite} from '@oracle-cx-commerce/core-commerce-reference-store/src/plugins/hooks/use-site';
import {useMobile} from '../plugins/hooks/use-mobile';
import OcqJbqLoader from '../plugins/components/ocq-jbq-loader';
import {useOrganizations} from '../plugins/hooks/use-organization';
import {getReturnablePackaging} from '../plugins/utils/get-returnable-packaging';

const GlobalContext = createContext({});

export const GlobalProvider = ({children}) => {
  const navigator = useNavigator();

  const [finishLoadOrganizations, setFinishLoadOrganizations] = useState(false);
  const [isAcceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);

  const [step, setStep] = useState(1);
  const [stepCheckout, setStepCheckout] = useState(null);
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [editProduct, setEditProduct] = useState({
    editStatus: false,
    commerceItem: null,
    productId: null,
    edited: {
      priceByKg: null,
      isLabeled: null,
      amountMaster: null,
      amountGram: null,
      returnable: null,
      unitPrice: null,
      isNational: null
    }
  });
  const [isLastProductOrder, setIsLastProductOrder] = useState(false);
  const [productHasBeenEdit, setProductHasBeenEdit] = useState(false);
  const [, , isPhone] = useMobile();
  const currentOrder = useSelector(getCurrentOrder);
  const site = useSite();
  useOrganizations(setFinishLoadOrganizations, finishLoadOrganizations);

  const [addNewProductCheckout, setAddNewProductCheckout] = useState(false);
  const [conpaniesConfig, setCompaniesConfig] = useState();
  const [hasExceededMinValue, setHasExceededMinValue] = useState(false);
  const [modalRemoveProducts, setModalRemoveProducts] = useState({isOpen: false, message: '', step: 0});
  const [isToggleStepAddress, setToggleStepAddress] = useState(false);
  const [isEnableLocalStore, setEnableLocalStore] = useState(false);
  const [pdtHasPackaging, setPdtHasPackaging] = useState(null);
  const [loading, setLoad] = useState(false);
  const [loadingTimeoutId, setLoadTimeoutId] = useState(undefined);

  const loadCompaniesConfig = useCallback(() => {
    if (site != null) {
      const valueSettings = JSON.parse(site?.siteSettings?.companyConfig?.companyConfig || '{}');
      setCompaniesConfig(valueSettings);
    }
  }, [site]);

  useEffect(() => {
    loadCompaniesConfig();
  }, [loadCompaniesConfig, site]);

  const toggleStepStep = value => {
    setStep(value);
  };

  const toggleStepCheckout = value => {
    setStepCheckout(value);
  };

  const toggleComponentFocus = (id, position) => {
    try {
      const element = document.getElementById(id);
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - position;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    } catch (error) {
      console.error('TAG - GLOBAL - toggleComponentFocus', error);
    }
  };

  const toggleFocus = useCallback((value, position) => {
    try {
      const id = `step${value}`;
      toggleComponentFocus(id, position);
    } catch (error) {
      console.error('TAG - GLOBAL - toggleFocus', error);
    }
  });

  const toggleAddProduct = value => {
    try {
      setIsLastProductOrder(false);
      setAddNewProduct(value);
    } catch (error) {
      console.error('TAG - GLOBAL - toggleAddProduct', error);
    }
  };

  useEffect(() => {
    if (isEmptyObject(currentOrder) || !isToggleStepAddress || pdtHasPackaging == null) return;
    try {
      setIsLastProductOrder(true);
      setAddNewProduct(false);
      const postition = pdtHasPackaging ? 8 : 7;
      toggleStepStep(postition);
      setTimeout(() => toggleFocus(postition, isPhone ? 180 : 280), 500);
    } catch (error) {
      console.error('TAG - toggleStepAddress', error);
    } finally {
      setToggleStepAddress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, isToggleStepAddress, pdtHasPackaging, toggleFocus]);

  const toggleStepAddress = () => {
    setTimeout(() => setToggleStepAddress(true), 100);
  };

  const clearPage = () => {
    setEnableLocalStore(false);
    localStorage.setItem('loadingId', JSON.stringify([]));
  };

  const toggleAddProductGoToCheckout = () => {
    setAddNewProductCheckout(false);
    clearPage();
    navigator('checkout-payment');
  };

  const setLoading = (visible, id = 'id') => {
    try {
      const loadingId = JSON.parse(localStorage.getItem('loadingId') || '[]');
      if (visible) {
        setLoad(true);
        localStorage.setItem('loadingId', JSON.stringify([...loadingId, id]));
        console.log('TAG - setLoading: on ', [...loadingId, id]);
        if (loadingTimeoutId) clearTimeout(loadingTimeoutId);
        setLoadTimeoutId(
          setTimeout(function () {
            setLoad(false);
            localStorage.setItem('loadingId', JSON.stringify([]));
          }, 10000)
        );
      } else {
        const loadingIdAux = loadingId.filter(e => e !== id);
        localStorage.setItem('loadingId', JSON.stringify(loadingIdAux));
        console.log('TAG - setLoading: off ', loadingIdAux, 'id: ', id);
        if (loadingIdAux.length === 0) {
          clearTimeout(loadingTimeoutId);
          setLoadTimeoutId(null);
          setLoad(false);
        }
      }
    } catch (error) {
      console.error('TAG - setLoading', error);
    }
  };

  const setPackagingProduct = useCallback(
    available => {
      try {
        if (!conpaniesConfig) return;
        let companyOrder = '';
        if (!isEmptyObject(currentOrder?.commerceItems))
          companyOrder = currentOrder?.dynamicProperties?.find(e => e.id === 'x_marca')?.value;
        else if (!companyOrder) {
          const order = JSON.parse(localStorage.getItem('order'));
          companyOrder = order?.idMarca;
        }

        let pdtHasPackaging = conpaniesConfig[String(companyOrder)]?.produto?.embalagemPadrao;
        if (typeof pdtHasPackaging === 'string') pdtHasPackaging = pdtHasPackaging?.toLowerCase?.() === 'true';
        setPdtHasPackaging(pdtHasPackaging && available);
      } catch (error) {
        console.error('TAG - pdtHasPackaging', error);
      }
    },
    [conpaniesConfig, currentOrder]
  );

  useEffect(() => {
    const commerceItems = Object.values(currentOrder?.commerceItems || {});
    if (!conpaniesConfig || isEmptyObject(commerceItems)) return;
    const {productId} = commerceItems.at(-1);
    const returnablePackaging = getReturnablePackaging(productId, site?.siteSettings);
    setPackagingProduct(returnablePackaging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conpaniesConfig, currentOrder, site]);

  return (
    <GlobalContext.Provider
      value={{
        step,
        toggleStepStep,
        toggleFocus,
        toggleComponentFocus,
        stepCheckout,
        toggleStepCheckout,
        addNewProduct,
        setAddNewProduct,
        editProduct,
        setEditProduct,
        toggleAddProduct,
        toggleStepAddress,
        isLastProductOrder,
        setIsLastProductOrder,
        addNewProductCheckout,
        setAddNewProductCheckout,
        toggleAddProductGoToCheckout,
        finishLoadOrganizations,
        setFinishLoadOrganizations,
        isAcceptPrivacyPolicy,
        setAcceptPrivacyPolicy,
        productHasBeenEdit,
        setProductHasBeenEdit,
        hasExceededMinValue,
        setHasExceededMinValue,
        modalRemoveProducts,
        setModalRemoveProducts,
        pdtHasPackaging,
        setPackagingProduct,
        setEnableLocalStore,
        isEnableLocalStore,
        setLoading,
        loading
      }}
    >
      {children}
      <OcqJbqLoader isLoading={loading} />
    </GlobalContext.Provider>
  );
};

export function useStep() {
  return useContext(GlobalContext);
}
