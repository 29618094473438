import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root';

export const render = ({components = {}, store, container}) => {
  const render = container.childElementCount ? ReactDOM.hydrate : ReactDOM.render;

  console.assert(typeof Root, 'Missing Root components');

  render(<Root components={components} store={store} />, container);
};
